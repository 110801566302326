const Envs = {
    Azure: {
        Url: 'https://controltowerapi.azurewebsites.net/',
        BuildName: 'Azure Prod',
    },
    AzureDev: {
        Url: 'https://controltowerapi-dev1.azurewebsites.net/',
        BuildName: 'Azure Dev',
    },
    Aws: {
        Url: 'https://auroraviewiot.com:59992/',
        BuildName: 'AWS',
    },
    Local: {
        Url: 'https://localhost:44305/',
        BuildName: 'Local',
    },
};
const ApiPrefix = 'api/v1/';

const env = Envs.Azure;
const buildNumber = '093';

export const AppSettings = {
    BuildVersion: `Build ${buildNumber} ${env.BuildName}`,
    ApiUrl: env.Url + ApiPrefix,
};
